import Vue from 'vue'

// axios
import axios from 'axios'
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.NODE_ENV == 'production'? process.env.VUE_APP_BASE_URL:process.env.VUE_APP_LOCAL_BASE_URL,
  headers: {'Accept': 'application/json'}
})
axiosIns.defaults.withCredentials = true;
Vue.prototype.$http = axiosIns

export default axiosIns
