export default [
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/auth/register/Register.vue'),
        meta: {
          layout: 'full',
          pageTitle: 'Register',
          resource: 'Auth',
          redirectIfLoggedIn: true
        },
      },
      {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/reset-password',
        name: 'auth-login',
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/forgot-password',
        name: 'auth-login',
        component: () => import('@/views/auth/ForgotPassword.vue'),
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
]
